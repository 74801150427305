import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout.js";
import EpisodePage from "../components/episodesPage.js";

const EpisodesTemplate = ({data, pageContext}) => {
  const episodes = data.episodes
  return (
    <Layout>
      <head>
        <Helmet
          title="OverMangaCast - Episodes"
        >
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
          <link rel="stylesheet" href="assets/css/main.css" />
        </Helmet>
        <noscript><link rel="stylesheet" href="assets/css/noscript.css" /></noscript>
      </head>
      <body class="is-preload">
        <div id="page-wrapper">
          <section id="wrapper">
            <header>
              <div class="inner">
                <h2>Episodes</h2>
                <p>New episode out every Thursday, Featuring a new manga, manwha, webcomic...</p>
              </div>
            </header>
          </section>
          <section class="wrapper">
            <div class="content">
              <EpisodePage eps={episodes.nodes} pageContext={pageContext}/>
            </div>
          </section>
        </div>
      </body>
    </Layout>
  )
}

export const query = graphql`
query($skip: Int!, $limit: Int!){
  episodes: allFeedOverMangaCast(
    skip: $skip
    limit: $limit
    sort: {
      fields: isoDate,
      order: DESC
    }
  ){
    totalCount
		nodes{
        ...episodeStub
    }
  }
}
`

export default EpisodesTemplate
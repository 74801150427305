import React, { Fragment } from "react";
import { Link } from "gatsby";
import EpisodeStub from "./episodeStub";


const EpisodeGroup = ({eps: episodes, pageContext}) => {
  const { currentPage, isFirstPage, isLastPage, totalPages } = pageContext
  const nextPage = `/episodes/${String(currentPage + 1)}`
  const prevPage = currentPage - 1 === 1 ? `/episodes` : `/episodes/${String(currentPage - 1)}`

  let prevLink = (<Link to={prevPage} className={"button small"} rel="prev">Previous</Link>)
  if(isFirstPage){
    prevLink = (<span class="button small disabled">Previous</span>)
  }

  let nextLink = (<Link to={nextPage} className="button small" rel="next">Next</Link>)
  if(isLastPage){
    nextLink = (<span class="button small disabled">Next</span>)
  }

  return (
    <div className="inner">
      <section className="features">
        {episodes.map(
          (episode, x) => {
            return <EpisodeStub ep={episode} />
          }
        )}
      </section>
      <ul className="pagination">
        <li>{prevLink}</li>
        {Array.from({ length: totalPages }, (_, index) => (
          <li><Link key={index} to={`/episodes/${index === 0 ? '' : index + 1}`} className={currentPage === (index+1) ? "page active" : "page"}>
            {index + 1}
          </Link></li>
        ))}
        <li>{nextLink}</li>
      </ul>
    </div>
  )
}

export default EpisodeGroup